var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "section section_sm section_white pt-0" }, [
      _c("div", { staticClass: "container" }, [_c("div", { ref: "form" })])
    ]),
    _c("div", { staticClass: "section section_sm section_white" }, [
      _c("div", { staticClass: "f-count" }, [
        _vm.$store.state.ordersDoneCount
          ? _c("div", { staticClass: "f-count__text" }, [
              _vm._v(
                "\n        Обработано " +
                  _vm._s(_vm.$store.state.ordersDoneCount) +
                  " выписок ЕГРН\n      "
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._m(0),
    _c("div", { staticClass: "section section_slides" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "app-carousel",
            _vm._l(4, function(i) {
              return _c("app-carousel-item", { key: i }, [
                _c("img", {
                  attrs: { src: "/images/free_page_examples/" + i + ".jpg" }
                })
              ])
            }),
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "section pt-0 text-center" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "app-button",
            { attrs: { size: "lg" }, on: { click: _vm.scrollToTop } },
            [_vm._v("\n        Заказать выписку ЕГРН\n      ")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "section section-steps section_white" }, [
      _c("div", { staticClass: "container", attrs: { id: "how-it-works" } }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v("\n        Как это работает\n      ")
        ]),
        _c("div", { staticClass: "step-items" }, [
          _vm._m(1),
          _c("div", { staticClass: "step-item" }, [
            _c("div", { staticClass: "step-item__img-wrap" }, [
              _c("div", { staticClass: "step-item__arrow" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "60",
                      stroke: "#000000",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 40 18.7"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "st0",
                      attrs: { d: "M4.2 9.4h32.1" }
                    }),
                    _c("path", {
                      staticClass: "st1",
                      attrs: { d: "M31.3 13.4l5-4-5-4" }
                    })
                  ]
                )
              ]),
              _c("img", {
                staticClass: "step-item__icon",
                attrs: { src: "/images/step-icons/tariff.png" }
              })
            ]),
            _c("div", { staticClass: "step-item__text" }, [
              _vm._v(
                "\n            В случае необходимости оплатите дополнительные услуги\n          "
              )
            ])
          ]),
          _c("div", { staticClass: "step-item" }, [
            _c("div", { staticClass: "step-item__img-wrap" }, [
              _c("div", { staticClass: "step-item__arrow" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "60",
                      stroke: "#000000",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 40 18.7"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "st0",
                      attrs: { d: "M4.2 9.4h32.1" }
                    }),
                    _c("path", {
                      staticClass: "st1",
                      attrs: { d: "M31.3 13.4l5-4-5-4" }
                    })
                  ]
                )
              ]),
              _c("img", {
                staticClass: "step-item__icon",
                attrs: { src: "/images/step-icons/24.png" }
              })
            ]),
            _c("div", { staticClass: "step-item__text" }, [
              _vm._v(
                "\n            Получите на email готовые выписки в PDF и ZIP формате с цифровой подписью\n          "
              )
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "section section-questions section_white" }, [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "questions" } },
        [
          _c("h2", { staticClass: "section-title" }, [
            _vm._v("\n        Частые вопросы\n      ")
          ]),
          _c(
            "app-collapse",
            [
              _c(
                "app-collapse-item",
                { attrs: { title: "Что такое отчет из ЕГРН?" } },
                [
                  _c("template", { slot: "icon" }, [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#collapse-icon-plus" }
                      })
                    ])
                  ]),
                  _vm._v(
                    "\n          С 15 июля 2016 года отчет из ЕГРН является единственным документом, который\n          подтверждает право собственности и содержит всю информацию по переходу прав и\n          обременениям на объект недвижимости. Бумажные свидетельства Росреестр больше не\n          выдаёт."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Все новые сведения о недвижимости сразу вносятся в базу реестра,\n          поэтому в выписке будет самая свежая и актуальная информация, а свидетельство могло быть\n          выдано десятки лет назад и устареть. Владельцы недвижимости могут доказать свое право\n          выпиской из ЕГРН, что уменьшит риск мошенничества в операциях с недвижимостью."
                  ),
                  _c("br")
                ],
                2
              ),
              _c(
                "app-collapse-item",
                { attrs: { title: "Зачем нужен отчет из ЕГРН?" } },
                [
                  _c("template", { slot: "icon" }, [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#collapse-icon-plus" }
                      })
                    ])
                  ]),
                  _vm._v(
                    "\n          При оформлении сделок с недвижимостью может понадобиться выписка из Единого\n          государственного реестра прав. Этот документ гарантия того, что продавец действительно\n          имеет право распоряжаться недвижимостью, что права собственника не ограничены судебными\n          решениями, залогом в банке или другими собственниками."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Отчет из ЕГРН\n          гарантирует, что предстоящая сделка законна. Заказав выписку перед сделкой, можно\n          оградить себя от мошенников и недобросовестных продавцов."
                  ),
                  _c("br")
                ],
                2
              ),
              _c(
                "app-collapse-item",
                { attrs: { title: "Что можно узнать из отчета ЕГРН?" } },
                [
                  _c("template", { slot: "icon" }, [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#collapse-icon-plus" }
                      })
                    ])
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "\n              Наименование органа, который осуществляет государственную регистрацию прав\n            "
                      )
                    ]),
                    _c("li", [
                      _vm._v("Дату подписания выписки и исходящий номер")
                    ]),
                    _c("li", [
                      _vm._v(
                        "Кадастровый (или условный) номер объекта недвижимости"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n              Назначение объекта, его адрес и площадь, (для зданий — этажность и материалы)\n            "
                      )
                    ])
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Данные о правообладателях — ФИО или название юрлица"
                      )
                    ]),
                    _c("li", [_vm._v("Вид зарегистрированного права")]),
                    _c("li", [
                      _vm._v(
                        "\n              Ограничения (обременения) права, дата и номер их государственной регистрации, сроки\n              на которые они установлены, а также данные о лицах, в пользу которых они\n              установлены.\n            "
                      )
                    ])
                  ])
                ],
                2
              ),
              _c(
                "app-collapse-item",
                { attrs: { title: "Почему выписка бесплатно?" } },
                [
                  _c("template", { slot: "icon" }, [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#collapse-icon-plus" }
                      })
                    ])
                  ]),
                  _vm._v(
                    "\n          В рамках акции наш сервис позволяет абсолютно бесплатно получить Выписку ЕГРН о переходе\n          прав. Ограничение: 1 выписка в 1 руки. Акция не влияет на сроки получения документов -\n          те же что в случае платных выписок. Сроки от 1 часа, но не более 3 суток.\n        "
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "section section-l" }, [
      _c(
        "div",
        { staticClass: "container text-center", attrs: { id: "last-section" } },
        [
          _c("h2", { staticClass: "section-title" }, [
            _vm._v("\n        Получите выписку ЕГРН\n      ")
          ]),
          _c("h5", { staticClass: "section-subtitle" }, [
            _vm._v("\n        абсолютно бесплатно\n      ")
          ]),
          _c(
            "div",
            { staticClass: "section-l__btn-wrap mt-md-5 mt-3" },
            [
              _c(
                "svg",
                {
                  staticClass: "d-md-none d-block arrow-mobile",
                  staticStyle: { fill: "#c9c9c9" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 35 70"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M31.5 47c-1.1-.9-2.7-.7-3.5.4L20.2 57V5.8c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5V57l-7.8-9.7c-.8-1-2.4-1.2-3.5-.3-1.1.9-1.2 2.4-.4 3.5l12.2 15.2c.5.6 1.2.9 1.9.9s1.5-.3 1.9-.9l12.2-15.2c1-1.1.9-2.6-.2-3.5z"
                    }
                  })
                ]
              ),
              _c(
                "svg",
                {
                  staticClass: "arrow-icon d-md-block d-none",
                  staticStyle: { fill: "#c9c9c9" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 80 180"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M54.1 109c-.8 0-1.6-.4-2-1.1-.8-1.1-.5-2.7.6-3.5 1.3-.9 6.8-4 11.6-6.6-15.9-1.3-29.2-8.3-38.5-20.2C8.9 56 8.5 24.1 13.2 3.4c.3-1.3 1.7-2.2 3-1.9 1.3.3 2.2 1.7 1.9 3-4.5 19.6-4.2 49.8 11.6 70 9 11.5 21.5 17.7 37.2 18.4l-1.8-2.3c-1.4-1.7-2.7-3.4-4.1-5.1-.7-.9-1.5-1.9-2.3-2.9-.9-1.1-.7-2.6.4-3.5 1.1-.9 2.6-.7 3.5.4 0 0 0 .1.1.1l6.4 7.9c.5.5.9 1.1 1.4 1.7 1.5 1.8 3.1 3.6 4.4 5.6 0 .1.1.1.1.2.1.3.2.5.3.8v.6c0 .2-.1.4-.2.6-.1.1-.1.3-.2.4-.1.2-.3.4-.5.6-.1.1-.3.2-.5.3-.1 0-.1.1-.2.1-1.2.6-16 8.6-18.1 10-.5.5-1 .6-1.5.6z"
                    }
                  })
                ]
              ),
              _c(
                "app-button",
                { attrs: { size: "lg" }, on: { click: _vm.scrollToTop } },
                [_vm._v("\n          Получить бесплатно\n        ")]
              )
            ],
            1
          )
        ]
      )
    ]),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section section_sm pb-0" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "section-title text-center" }, [
          _vm._v("\n        Образцы выписок\n      ")
        ]),
        _c("h5", { staticClass: "section-subtitle text-center" }, [
          _vm._v(
            "\n        Отчет ЕГРН о переходе прав / Отчет об объекте (платно)\n      "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-item" }, [
      _c("div", { staticClass: "step-item__img-wrap" }, [
        _c("img", {
          staticClass: "step-item__icon",
          attrs: { src: "/images/step-icons/account.png" }
        })
      ]),
      _c("div", { staticClass: "step-item__text" }, [
        _vm._v(
          "\n            Введите кадастровый номер или адрес объекта недвижимости в поле поиска\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section section_sm pt-3" }, [
      _c("div", { staticClass: "container", attrs: { id: "text-note" } }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-8 small text-center" }, [
            _vm._v(
              "\n          * предложение действует только на 1 продукт: Отчет ЕГРН о переходе прав или Выписка об\n          объекте. Какой продукт будет бесплатным сервис определяет самостоятельно и\n          автоматически.\n        "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }