import Vue from 'vue';
import Component from 'vue-class-component';
import cloneDeep from 'lodash/cloneDeep';

// import AppEgrnFreeForm from 'shared/module/EgrnFreeForm/EgrnFreeForm.vue';
import loadExternalScript from 'shared/utils/loadExternalScript';

@Component({
  // components: { AppEgrnFreeForm },
})
export default class FreeEgrn extends Vue {
  // private properties
  private _vm: any;

  getProducts() {
    return new Promise(resolve => {
      if (this.$store.state.products.length) {
        return resolve(cloneDeep(this.$store.state.products));
      }

      this.$api.products.List().then(resolve);
    });
  }

  // data
  formComponent: any = null;

  created() {
    document.title = 'Отчет из ЕГРН - закажите бесплатно из Росреестра. Получите выписку на квартиру, дом или земельный участок';
  }

  async mounted() {
    const w: any = window;

    w.__RC_OPTIONS__ = { preventAutoInit: true };

    // await loadExternalScript('https://realtycloud.ru/forms/custom/RealtyCloudEGRN.umd.min.js');

    this._vm = w.RealtyCloudEGRN.init(this.$refs.form, {
      partnerId: '2835afaa-2572-11eb-8be7-52540021e83f',
      title: 'Отчет из ЕГРН - Бесплатно',
      subtitle: 'Получите официальные сведения о недвижимости из Росреестра на квартиру, дом, землю, гараж без посещения МФЦ',
      orderTitle: 'Заказ выписки на объект',
      buttonText: 'Заказать бесплатно',
      selectedProducts: [ 'EgrnObject', 'EgrnRightList', 'RiskAssessment' ],
      defaultCheckedProducts: [ 'EgrnObject', 'EgrnRightList' ],
      enableFastProducts: true,
      allowFreeOrder: true,
      imageUrl: 'https://egrn.realtycloud.ru/images/rosreestr-bg.png',
    });

    const events: SimpleObject = w.RealtyCloudEGRN.EVENT_NAMES;

    w.RealtyCloudEGRN.events.on(events.FORM_SUBMIT, () => {
      this.$analytics.send('button_click');
    });

    w.RealtyCloudEGRN.events.on(events.OBJECT_SELECTED, () => {
      this.$analytics.send('object_selected');
    });

    w.RealtyCloudEGRN.events.on(events.PAYMENT_SUCCESS, () => {
      this.$analytics.send('payment_success');
    });
  }

  beforeDestroy() {
    if (this._vm) {
      this._vm.$destroy();
    }
  }

  scrollToTop() {
    window.scroll(0, 0);
  }
}
